<template>
  <transition :name="transitionName">
    <div v-show="visible" class="sf-mega-menu" :class="[{ 'is-active': active }, { 'is-absolute': isAbsolute }]">
      <SfBar class="sf-mega-menu__bar" :title="active || title" :back="true" @click:back="back" />
      <div class="sf-mega-menu__content grid-container">
        <div class="sf-mega-menu__menu">
          <slot />
        </div>
        <div class="sf-mega-menu__aside" :class="{ 'display-none': !$slots.aside }">
          <!-- @slot @deprecated will be removed in 1.0.0 -->
          <SfMenuItem class="sf-mega-menu__aside-header desktop-only" :label="asideTitle" />
          <slot name="aside" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapMobileObserver, unMapMobileObserver } from './utilities/mobile-observer'
export default {
  name: 'SfMegaMenu',
  props: {
    title: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    isAbsolute: {
      type: Boolean,
      default: false,
    },
    transitionName: {
      type: String,
      default: 'sf-fade',
    },
    /**
     * @deprecated will be removed in 1.0.0
     */
    asideTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      active: '',
    }
  },
  provide() {
    const megaMenu = {}
    Object.defineProperty(megaMenu, 'active', {
      get: () => this.active,
    })
    Object.defineProperty(megaMenu, 'changeActive', {
      value: this.changeActive,
    })
    return { megaMenu }
  },
  computed: {
    ...mapMobileObserver(),
  },
  watch: {
    isMobile: {
      handler() {
        this.active = ''
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    unMapMobileObserver()
  },
  methods: {
    changeActive(payload) {
      this.active = payload
      this.$emit('change', payload)
    },
    back() {
      if (!this.active) {
        this.$emit('close')
      }
      this.active = ''
    },
  },
}
</script>

<style lang="scss">
@use './styles/organisms/SfMegaMenu.scss';
html.theme--storefront {
  .sf-mega-menu__content {
    display: grid;
    margin: 0;
    max-width: 100%;

    @include for-mobile {
      padding: 0;
    }
  }
}
</style>
