<template>
  <div class="search__content">
    <h5 v-if="content.length" class="h5 search__header" :class="{ 'hide-mobile': hasSearch }">
      {{ $t('Info & inspiration') }}
    </h5>

    <SfList v-if="content.length" class="search__content-list">
      <SfListItem v-for="item in content" :key="item.item_id" class="search__content-list-item">
        <SfLink class="search__content-list-item-link" :to="formatUrl(item.url)" :title="getTitle(item)">
          <span v-dompurify-html="getTitle(item)" class="search__content-list-item-label" />
        </SfLink>
      </SfListItem>
    </SfList>
  </div>
</template>

<script lang="ts" setup>
defineProps({
  content: {
    type: Array,
    default: () => [],
  },
  hasSearch: {
    type: Boolean,
    required: true,
  },
})

const getTitle = (item) => item.titleArticleFeed || item.title
</script>

<style lang="scss" scoped>
h5.search__header {
  margin: 0 0 var(--spacer-sm);

  @include for-mobile {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: var(--spacer-xs);
  }

  &.hide-mobile {
    @include for-mobile {
      display: none;
    }
  }
}

.search__content {

  @include for-desktop {
    margin-top: var(--spacer-base);
  }

  &-list-item {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-top: var(--spacer-2xs);

    &:after {
      content: '';
      display: block;
      clear: both;
    }

    &-link {
      position: relative;
      padding-left: var(--spacer-sm);
      cursor: pointer;

      text-overflow: ellipsis;
      overflow: hidden;
      height: 1.25rem;
      white-space: nowrap;

      &:before {
        content: '/';
        position: absolute;
        left: 0;
        width: var(--spacer-sm);
        font-weight: var(--font-weight--normal) !important;
        text-align: center;
      }

      &:not(:first-of-type):not(:last-of-type):not(:nth-child(2)) {
        display: none;
      }

      &:not(:first-of-type):not(:last-of-type) {
        flex: 0 0 27px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0;

        &::before {
          font-size: var(--font-size--xs);
        }

        &:after {
          content: '...';
          font-size: var(--font-size--xs);
        }
      }

      &:first-child {
        padding-left: 0;

        &:before {
          display: none;
        }
      }
    }

    .search__content-list-item__count {
      margin-left: var(--spacer-2xs);
    }

    @include for-desktop {
      display: none;

      &:nth-child(-n + 5) {
        display: flex;
      }
    }
  }

  @include for-mobile {
    order: 2;
    padding: var(--spacer-xs) var(--spacer-15);
  }

  @include for-desktop {
    grid-area: content;
  }

  &-list {
    --list-item-margin: 0 0 var(--spacer-xs);
  }
}
</style>
