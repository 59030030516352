<template>
  <component :is="componentIs" class="sf-menu-item" :data-testid="label" v-bind="bind">
    <slot name="icon" />
    <slot name="label" v-bind="{ label }">
      <span class="sf-menu-item__label">{{ label }}</span>
    </slot>
    <slot name="count" v-bind="{ count }">
      <span class="sf-menu-item__count">{{ count }}</span>
    </slot>
    <slot name="mobile-nav-icon" v-bind="{ icon }">
      <SfIcon
        class="sf-menu-item__mobile-nav-icon mobile-only"
        :class="{ 'display-none': !icon }"
        :icon="icon"
        size="14px"
      />
    </slot>
  </component>
</template>
<script>
import Button from './Button.vue'
import Link from './Link.vue'

export default defineComponent({
  name: 'SfMenuItem',
  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: [String, Array],
      default: 'chevron_right',
    },
    count: {
      type: [String, Number],
      default: '',
    },
    link: {
      type: [String, Object],
      default: null,
    },
  },
  computed: {
    bind() {
      const bind = {}
      if (this.link) {
        bind.link = this.link
      } else {
        bind.class = 'sf-button--pure'
      }
      return bind
    },
    componentIs() {
      return this.link ? Link : Button
    },
  },
})
</script>
<style lang="scss">
@use './styles/molecules/SfMenuItem.scss';
</style>
