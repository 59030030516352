<template>
  <header class="top-header" :class="{ 'top-header--sticky': isSticky }" :style="`padding-top: ${paddingTop}px`">
    <div
      ref="topHeaderContent"
      class="top-header__content"
      :class="{ 'is-visible': isTopHeaderVisible }"
      :style="headerContentStyle"
    >
      <TopBar />
      <AppHeader />

      <MobileNav />
      <TopNav class="desktop-only" />
      <br-component component="header" />
    </div>
  </header>
</template>

<script setup lang="ts">
const uiState = useUiState()
const { isTopHeaderVisible } = storeToRefs(uiState)
const { toggleTopHeader } = uiState
const { isMobileOrTablet } = useDevice()

const lastScrollY = ref(0)
const lastOnScrollChange = ref(0)
const lastVisibleScrollY = ref(0)
const invisibleScrollOffset = ref(0)
const ticking = ref(false)
const scrollMargin = 90
const paddingTop = ref(0)
const topHeaderContent = ref()
const isSticky = ref(false)

const headerContentStyle = computed(() =>
  !isMobileOrTablet && isTopHeaderVisible.value ? null : `margin-top: -${invisibleScrollOffset.value}px`,
)

const determineVisibility = () => {
  let isVisible = true
  const scrollY = window.scrollY

  if (scrollY === 0) {
    return
  }

  // Always show on top of page
  if (scrollY < paddingTop.value) {
    return toggleTopHeader(true)
  }

  const scrollDirection = lastScrollY.value < scrollY ? 'down' : 'up'

  // Hide after amount scrolled
  if (scrollY > scrollMargin) {
    isVisible = false
  }

  if (scrollDirection === 'up') {
    // Show after amount scrolled up
    if (lastOnScrollChange.value - scrollY > scrollMargin) {
      isVisible = true
    }
  } else {
    lastOnScrollChange.value = scrollY
  }

  lastScrollY.value = scrollY
  if (!isMobileOrTablet) invisibleScrollOffset.value = scrollY - lastVisibleScrollY.value
  else invisibleScrollOffset.value = 0

  if (isVisible !== isTopHeaderVisible.value) {
    return toggleTopHeader(isVisible)
  }
}

const update = () => {
  ticking.value = false
  determineVisibility()
}

const requestTick = () => {
  if (!ticking.value) {
    requestAnimationFrame(update)
  }
  ticking.value = true
}

// Ensure proper height on resize screen or adding/removing elements (like a notification)
const resizeObserver = () => {
  const observer = new ResizeObserver(() => {
    if (isSticky.value) paddingTop.value = topHeaderContent.value?.offsetHeight
  })
  observer.observe(topHeaderContent.value)
}

onMounted(() => {
  isSticky.value = true
  window.addEventListener('scroll', requestTick, false)
  window.addEventListener('touchmove', requestTick, false)
  if (isSticky.value) paddingTop.value = topHeaderContent.value?.offsetHeight
  resizeObserver()
})

watch(isTopHeaderVisible, () => {
  if (!isMobileOrTablet && !isTopHeaderVisible.value) lastVisibleScrollY.value = window.scrollY
})
</script>

<style lang="scss" scoped>
html.theme--storefront {
  .top-header {
    position: relative;
    width: 100%;
    z-index: 4;

    &__content {
      position: relative;
      z-index: 2;
      top: 0;
      left: 0;
      width: inherit;
      transition: transform 0.3s ease-out;

      &:not(.is-visible) {
        transform: translateY(-100%);
      }

      &:has(.nav-item:hover) {
        transform: translateY(0);
      }
    }

    &--sticky {
      padding-top: 118px;

      @media (min-width: 1024px) {
        padding-top: 191px;
      }

      .top-header__content {
        position: fixed;
      }
    }
  }
}
</style>
