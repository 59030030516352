<template>
  <br-page v-if="visible" :configuration :page :mapping>
    <slot :data />
  </br-page>
</template>

<script setup lang="ts">
import { initialize } from '@bloomreach/spa-sdk'

const { mapping } = useSfBloomreachComponents()
const data = setPageData()
const { buildConfiguration } = useBuildConfiguration()
const configuration = buildConfiguration()
const route = useRoute()
const { trackPageEvent } = useGTMComposable()

let page = ref(initialize(configuration, data?.page))

if (route.meta.type === 'ERROR') {
  showError(route.meta.data)
}

// Hack to reinitialize the page when the website is used via Bloomreach CMS
const visible = ref(!route.query.token)
onMounted(async () => {
  if (route.query.token) {
    page.value = await initialize(configuration)
    visible.value = true
  }
  trackPageEvent()
})
</script>
